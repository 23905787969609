<template>
  <v-navigation-drawer permanent color="#272727" style="color:#fff" id="core-navigation-drawer" app
    :right="$vuetify.rtl" :dark="'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular">
          <v-row align="center" justify="center">
            <v-col align="center" justify="center" cols="12" md="6">
              <h4 style="color:#fff">CMS </h4>
            </v-col>
            <v-col class="text-center">
              <!-- <img width="31px" src="../../assets/project-management.svg" /> -->
            </v-col>

          </v-row>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    {{changeLang()}}
    <!-- <v-divider class="mb-1" /> -->

    <v-list-item two-line>


      <v-list-item-avatar>
        <img
          src="https://cdn1.vectorstock.com/i/1000x1000/51/05/male-profile-avatar-with-brown-hair-vector-12055105.jpg" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title style="color:#fff">{{ $store.state.AdminInfo.name }}</v-list-item-title>
        <v-list-item-subtitle style="color:#fff">{{
         $store.state.AdminInfo.name
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-divider light style="color#fff" /> -->


    <v-list dense nav>
      <v-list-item style="color:#fff !important" v-for="item in items" :key="item.title" link :to="item.to">
        <v-list-item-icon>
          <v-icon style="color:#fff !important">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {

    data() {
      return {
        items: [
          
          {
            title: 'الكتب',
            icon: 'fas fa-newspaper',
            to: "/books"
          },
          {
            title: 'ملف الاكسل',
            icon: 'fas fa-newspaper',
            to: "/exl"
          },
          {
            title: 'الكتاب',
            icon: 'fas fa-newspaper',
            to: "/writers"
          },
          {
            title: 'معلومات التواصل',
            icon: 'fas fa-newspaper',
            to: "/info"
          },
          {
            title: 'أتصــل بنا',
            icon: 'fas fa-newspaper',
            to: "/contactus"
          },
          // {
          //   title: 'منشورات',
          //   icon: 'fas fa-newspaper',
          //   to: "/example"
          // },

        ],
        right: null,
      }
    },
    methods: {
      changeLang() {
        // this.items[0].title = this.$t("Drawer.statistics")
      ///   this.items[0].title = 'المتقدمين'
      // this.items[1].title = this.$t("Drawer.qurans")
         
        // this.items[3].title = this.$t("Drawer.about")
        // this.items[2].title = this.$t("Drawer.Gallery")
          //  this.items[4].title = this.$t("Drawer.messages")
        // this.items[5].title = this.$t("Drawer.GalleryCats")
       
        // this.items[2].title = this.$t("Drawer.books")
        // // this.items[6].title = this.$t("Drawer.cat_arts")
     
        // this.items[3].title = this.$t("Drawer.videos")
        
        // this.items[4].title = this.$t("Drawer.libraryImage")

        // this.items[5].title = this.$t("Drawer.callUs")


      },
    },
  }
</script>